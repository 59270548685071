import React, { FC, Suspense, lazy, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

// component
import Loadable from 'components/Loadable';
import LoaderSpinner from 'components/LoaderSpinner';

// layouts
import GuestLayout from 'layout/GuestLayout';
import AuthLayout from 'layout/AuthLayout';
// import VideoLayout from 'layout/VideoLayout';
import ZoomVideoLayout from 'layout/ZoomVideoLayout';
import OnBoardingLayout from 'layout/OnBoardingLayout';
import ScrollToTop from 'components/ScrollToTop';
import { GlobalLoading } from 'hooks/useGlobalLoading';
// import ZoomVideo from 'components/ZoomVideo';

// pages with loadable component
const NotFound = Loadable(lazy(() => import('pages/error/404')));
const Login = Loadable(lazy(() => import('pages/guest/Login')));
const SignUp = Loadable(lazy(() => import('pages/guest/SignUp')));
const ForgotPassword = Loadable(lazy(() => import('pages/guest/ForgotPassword')));
const AdminAuthorization = Loadable(lazy(() => import('pages/guest/AdminAuthorization')));
const OnBoarding = Loadable(lazy(() => import('pages/auth/OnBoarding')));
const Dashboard = Loadable(lazy(() => import('pages/auth/Dashboard')));
const Profile = Loadable(lazy(() => import('pages/auth/Profile')));
// const Consultations = Loadable(lazy(() => import('pages/auth/Consultations')));
const ConsultationSummary = Loadable(lazy(() => import('pages/auth/ConsultationSummary')));
// const Prescriptions = Loadable(lazy(() => import('pages/auth/Prescriptions')));
const Schedule = Loadable(lazy(() => import('pages/auth/Schedule')));
const ChangePassword = Loadable(lazy(() => import('pages/auth/ChangePassword')));
const PatientList = Loadable(lazy(() => import('pages/auth/PatientList')));
// const PatientProfile = Loadable(lazy(() => import('pages/auth/PatientProfile')));
const WaitingRoom = Loadable(lazy(() => import('pages/auth/WaitingRoom')));
const MeetingRoom = Loadable(lazy(() => import('pages/auth/MeetingRoom')));
const ResetPassword = Loadable(lazy(() => import('pages/common/ResetPassword')));
const ProductList = Loadable(lazy(() => import('pages/auth/ProductList')));

const Router: FC = () => {
  const { isLoading, isLoggedIn, isOnBoarding, token, user } = useAuth();
  const [showLoader, setShowLoader] = useState<boolean>(false);

  return (
    <GlobalLoading.Provider value={{ showLoader, setShowLoader }}>
      {showLoader && (
        <div className="flex justify-center items-center fixed h-full w-full z-50 bg-black bg-opacity-50">
          <LoaderSpinner className="w-10 h-20" />
        </div>
      )}
      <Suspense fallback={null}>
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              {isLoading ? (
                <Route
                  path="*"
                  element={
                    <div className="flex justify-center items-center fixed h-full w-full">
                      <LoaderSpinner className="w-10 h-20" />
                    </div>
                  }
                />
              ) : (
                <>
                  <Route path="/authorization" element={<AdminAuthorization />} />

                  {!isLoggedIn && !token && (
                    <Route path="/" element={<GuestLayout />}>
                      <Route index element={<Login />} />
                      <Route path="login" element={<Login />} />
                      <Route path="sign-up" element={<SignUp />} />
                      <Route path="forgot-password" element={<ForgotPassword />} />
                      <Route path="reset-password/:token" element={<ResetPassword />} />
                      <Route path="*" element={<NotFound />} />
                    </Route>
                  )}

                  {isLoggedIn && token && !isOnBoarding && user && (
                    <>
                      <Route path="/" element={<AuthLayout hasSideBar hasTopBar />}>
                        <Route path="*" element={<Navigate to="/dashboard" />} />
                        <Route index element={<Dashboard />} />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="patient-list" element={<PatientList />} />
                        {/* <Route path="patient-list/:id" element={<PatientProfile />} /> */}
                        {/* <Route path="consultations" element={<Consultations />} />*/}
                        <Route path="consultation-summary/:id" element={<ConsultationSummary />} />
                        {/* <Route path="prescriptions" element={<Prescriptions />} /> */}
                        <Route path="change-password" element={<ChangePassword />} />
                        <Route path="schedule" element={<Schedule />} />
                        <Route path="profile" element={<Profile />} />
                        <Route path="product-list" element={<ProductList />} />
                      </Route>

                      <Route path="waiting-room/:id" element={<ZoomVideoLayout />}>
                        <Route index element={<WaitingRoom />} />
                      </Route>

                      <Route path="meeting-room/:id" element={<ZoomVideoLayout />}>
                        <Route index element={<MeetingRoom />} />
                        {/* <Route index element={<ZoomVideo />} /> */}
                      </Route>

                      <Route path="reset-password/:token" element={<AuthLayout />}>
                        <Route index element={<ResetPassword />} />
                      </Route>
                    </>
                  )}

                  {isLoggedIn && token && isOnBoarding && user && (
                    <Route path="/" element={<OnBoardingLayout />}>
                      <Route path="*" element={<Navigate to="/on-boarding" />} />
                      <Route index element={<OnBoarding />} />
                      <Route path="on-boarding" element={<OnBoarding />} />
                    </Route>
                  )}
                </>
              )}
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      </Suspense>
    </GlobalLoading.Provider>
  );
};

export default Router;
